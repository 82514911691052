import React, { useRef, useState } from "react"
import { useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Wrapper from "../components/wrapper"
import Layout from "../components/layout"
import { TransitionMixin, media } from "../components/helpers"
import Plus from "../images/plus.inline.svg"
import SEO from "../components/seo"

const BannerContainer = styled.section`
  position: relative;
  .image-wrap {
    position: relative;
    .gatsby-image-wrapper {
      height: 80vh;
    }
    .wrapper-class {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 23vh;
      ${media.medium`top: 28vh;`}
      .content-container {
        max-width: 90vw;
        margin: 0 auto;
        ${media.medium`max-width: 700px;`}
        h1 {
          color: #fff;
          margin: 0 0 30px;
          font-size: 30px;
          line-height: 1.1;
          ${media.medium`font-size: 40px;`}
        }

        p {
          color: #fff;
          margin: 0 auto 30px;
          line-height: 1.4;
          max-width: 90%;
          ${media.small`max-width: 70%;`}
          ${media.medium`max-width: 700px;;`}
        }

        .btn-wrap {
          a {
            max-width: 250px;
            width: 100%;
            display: inline-block;
            color: #2a2a2a;
            background-color: #fff;
            text-decoration: none;
            padding: 10px 0;
            border-radius: 20px;
            font-weight: bold;
            line-height: 1;
            font-size: 16px;

            ${TransitionMixin(".25s")};

            &:hover {
              background-color: #2a2a2a;
              color: #fff;
            }
          }
        }
      }
    }
  }

  > .wrapper-class {
    position: relative;
    ${media.medium`max-width: 700px;`}
  }
`

const QuadContainer = styled.section`
  padding: 70px 0;

  .wrapper-class,
  .flex-wrap {
    > .title-wrap {
      text-align: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 40px;
      ${media.small`margin-bottom: 70px;`};
      ${media.medium`margin-bottom: 100px;`};

      h2 {
        margin: 0;
        line-height: 1.3;
        color: #2a2a2a;
        font-size: 20px;
        font-weight: 300;

        ${media.medium`max-width: 85vw; font-size: 24px;`};
      }
    }
    .quad-item-container {
      flex: 1 1 100%;
      margin-bottom: 40px;
      ${media.small`flex: 1 1 50%;`};
      ${media.medium`flex: 1;margin-bottom; 0;`};

      .inner-wrap {
        max-width: 90%;
        margin: 0 auto;
        .icon-wrap {
          text-align: center;
          .gatsby-image-wrapper {
            max-width: 70px;
            height: 60px;
            margin: 0 auto;

            img {
              object-fit: contain !important;
            }
          }
        }
        .title-wrap {
          text-align: center;
          h4 {
            margin: 20px 0 10px;
          }
          p {
            font-weight: 300;
            font-size: 13px;
            line-height: 1.5;
            max-width: 240px;
            margin: 0 auto;
          }
        }
      }
    }
  }
`

const FAQContainer = styled.section`
  padding: 70px 0px;
  .flex-wrap {
    .blurb-container {
      flex: 1 1 100%;
      margin-bottom: 70px;
      ${media.medium`flex: 1; margin-bottom: 0px;`};

      ${media.medium`padding-right: 70px;`}
      h3 {
        margin: 0 0 20px;
        color: #2a2a2a;
        ${media.medium`font-size: 28px;`}
      }
      p {
        font-size: 14px;
        color: $black;
        margin: 0 0 20px;
        font-weight: 300;
        max-width: 400px;
        line-height: 1.5;
      }
      .btn-wrap {
        a {
          background-color: #1ab78d;
          color: #fff;
          text-decoration: none;
          padding: 10px 0;
          width: 200px;
          border-radius: 20px;
          display: inline-block;
          text-align: center;
          border: 1px solid #1ab78d;
          line-height: 1;

          ${TransitionMixin(".25s")};

          &:hover {
            color: #1ab78d;
            background-color: #fff;
          }
        }
      }
    }
    .accordion-container {
      flex: 1 1 100%;
      ${media.medium`flex: 1;`};
      .inner-wrap {
        .acc-item {
          padding: 20px 0;
          border-bottom: 1px solid #d4d4d4;
          &:first-child {
            padding-top: 0px;
          }
        }
        .acc-header {
          a {
            color: #2a2a2a;
            text-decoration: none;
            font-weight: bold;
            display: flex;
            align-items: center;
            font-size: 20px;

            span {
              flex: 1;
              text-align: right;
              svg {
                width: 24px;
                height: 24px;
                ${TransitionMixin(".25s")};
              }
            }
            &.active {
              span {
                svg {
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
        .acc-body {
          max-height: 0;
          opacity: 0;
          overflow: hidden;

          ${TransitionMixin(".25s")};

          &.active {
            opacity: 1;
            max-height: 5000px;
            padding: 20px 0;
          }
          p {
            line-height: 1.5;
            color: #2a2a2a;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`

const CTAContainer = styled.section`
  background-color: #fafafa;
  padding: 70px 0;
  text-align: center;

  h3 {
    margin: 0 0 20px;
    ${media.medium`font-size: 24px;`};
  }

  p {
    font-weight: 300;
    font-size: 14px;
    margin: 0 auto 20px;
    max-width: 450px;
    line-height: 1.5;
    ${media.medium`font-size: 16px;`};
  }

  .btn-wrap {
    text-align: center;
    a {
      color: #fff;
      display: inline-block;
      padding: 10px 0;
      width: 200px;
      text-decoration: none;
      background-color: #1ab78d;
      border-radius: 20px;
      border: 1px solid #1ab78d;
      line-height: 1;
      ${TransitionMixin(".25s")};

      &:hover {
        color: #1ab78d;
        background-color: #fff;
      }
    }
  }
`

const Rent = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { title: { eq: "Rent" } }) {
        edges {
          node {
            date
            id
            title
            slug
            acf {
              banner_headline
              banner_blurb
              banner_button {
                title
                url
                target
              }
              banner_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              icon_section_title_blurb
              icon_grid {
                title
                blurb
                icon {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              faq_section_title
              faq_section_blurb
              faq_section {
                question
                answer
              }
              faq_button_url
              faq_button_text
            }
          }
        }
      }
    }
  `)
  let page = data.allWordpressPage.edges[0].node
  console.group(page)

  const [activeAccordion, setActiveAccordion] = useState(-1)

  function toggleAcc(e, index) {
    e.preventDefault()
    console.log("index", index)
    let clickedItem = document.querySelectorAll(".acc-item .acc-header")[index]
    console.log("yuh", clickedItem)
    if (index == activeAccordion) {
      // close acc
      //   clickedItem.querySelector("a").classList.remove("active")
      //   clickedItem.querySelector(".acc-body").classList.remove("active")
      setActiveAccordion(-1)
    } else {
      setActiveAccordion(index)
    }
  }
  return (
    <Layout>
      <SEO title="Rent" />
      <BannerContainer>
        <div className="image-wrap">
          <Img
            fluid={page.acf.banner_image.localFile.childImageSharp.fluid}
          ></Img>
          <Wrapper styleClass size="mobile-md fw">
            <div className="content-container">
              <h1>{page.acf.banner_headline}</h1>
              <div className="wrapper xss">
                <p>{page.acf.banner_blurb}</p>
              </div>
              <div className="btn-wrap">
                <a href={page.acf.banner_button.url}>
                  {page.acf.banner_button.title}
                </a>
              </div>
            </div>
          </Wrapper>
        </div>
      </BannerContainer>

      <QuadContainer>
        <Wrapper styleClass size="xs">
          <div className="title-wrap">
            <h2>{page.acf.icon_section_title_blurb}</h2>
          </div>
        </Wrapper>
        <Wrapper flex>
          {page.acf.icon_grid.map(item => {
            return (
              <div key={item.id} className="quad-item-container">
                <div className="inner-wrap">
                  <div className="icon-wrap">
                    <Img
                      fluid={item.icon.localFile.childImageSharp.fluid}
                    ></Img>
                  </div>
                  <div className="title-wrap">
                    <h4>{item.title}</h4>
                    <p>{item.blurb}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </Wrapper>
      </QuadContainer>

      <Wrapper size="xss">
        <section className="divider wrapper xss">
          <hr />
        </section>
      </Wrapper>

      <FAQContainer>
        <Wrapper size="sm" flex>
          <div className="blurb-container">
            <h3>{page.acf.faq_section_title}</h3>
            <p>{page.acf.faq_section_blurb}</p>
            <div className="btn-wrap">
              <a href={page.acf.faq_button_url}>{page.acf.faq_button_text}</a>
            </div>
          </div>
          <div class="accordion-container">
            <div class="inner-wrap">
              {page.acf.faq_section.map((item, index = 0) => {
                index = index + 1
                return (
                  <div class="acc-item">
                    <div class="acc-header">
                      <a
                        className={activeAccordion == index ? "active" : ""}
                        onClick={e => toggleAcc(e, index)}
                        href="#"
                      >
                        {item.question}
                        <span>
                          <Plus />
                        </span>
                      </a>
                    </div>
                    <div
                      className={
                        activeAccordion == index
                          ? "active acc-body"
                          : "acc-body"
                      }
                    >
                      <p>{item.answer}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Wrapper>
      </FAQContainer>

      <CTAContainer>
        <Wrapper>
          <div className="title-wrap">
            <h3>Join, ChargeTree today!</h3>
            <p>
              ChargeTree costs $2 for the first 3 hours or $11 for the day.
              Return to any Kiosk!
            </p>
            <div className="btn-wrap">
              <a href="#">Sign Up</a>
            </div>
          </div>
        </Wrapper>
      </CTAContainer>
    </Layout>
  )
}

export default Rent
